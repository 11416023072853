import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  // SECCIÓN PRINCIPAL
  {
    id: "main-section",
    type: "section",
    title: "Página principal",
    icon: "package",
    children: [
      {
        id: "start",
        title: "Inicio",
        type: "item",
        icon: "home",
        url: "general/home",
      },
    ],
  },

  //SECCIÓN DE GESTIÓN
  {
    id: "gestion-section",
    type: "section",
    title: "Gestión",
    icon: "package",
    children: [
      {
        id: "patients",
        title: "Pacientes",
        type: "collapsible",
        icon: "users",
        children: [
          {
            id: "management_patient",
            title: "Gestionar",
            type: "item",
            icon: "book-open",
            url: "patients/manage-patient",
          },
          {
            id: "create_patient",
            title: "Crear",
            type: "item",
            icon: "plus",
            url: "patients/create-patient",
          },
          // {
          //   id: "edit_patient",
          //   title: "Editar",
          //   type: "item",
          //   icon: "edit",
          //   url: "patients/edit-patient",
          // },
          // {
          //   id: "see_patient",
          //   title: "Ver",
          //   type: "item",
          //   icon: "eye",
          //   url: "patients/see-patient",
          // },
          {
            id: "list_patients",
            title: "Listar",
            type: "item",
            icon: "list",
            url: "patients/list-patients",
          },
          // {
          //   id: "management_patients",
          //   title: "Gestión Grupal",
          //   type: "item",
          //   icon: "folder",
          //   url: "patients/gestion-patients",
          // },
        ],
      },

      // {
      //   id: "indicators",
      //   title: "Indicadores",
      //   type: "collapsible",
      //   icon: "pie-chart",
      //   children: [
      //     {
      //       id: "indicadores_proporcion",
      //       title: "Proporción",
      //       type: "item",
      //       icon: "bar-chart",
      //       url: "indicators/proportion-indicators",
      //     },
      //     {
      //       id: "indicadores_oportunidad",
      //       title: "Oportunidad",
      //       type: "item",
      //       icon: "bar-chart",
      //       url: "indicators/opportunity-indicators",
      //     },
      //     {
      //       id: "indicadores_estadisticos",
      //       title: "Estadisticos",
      //       type: "item",
      //       icon: "bar-chart",
      //       url: "indicators/stadistic-indicators",
      //     },
      //   ],
      // },
      {
        id: "alerts",
        title: "Alertas",
        type: "item",
        icon: "alert-triangle",
        url: "alerts/alerts",
      },
    ],
  },

  //SECCIÓN DE PARAMETRIZACIÓN
  {
    id: "parametrization-section",
    type: "section",
    title: "Parametrización",
    role: ["Gestor"],
    children: [
      // {
      //   id: "parametrization-routes",
      //   title: "Routes",
      //   type: "collapsible",
      //   icon: "users",
      //   children: [
      //     {
      //       id: "create-route",
      //       title: "Crear Ruta",
      //       type: "item",
      //       icon: "plus",
      //       url: "pyp",
      //     },
      //     {
      //       id: "edit-route",
      //       title: "Editar Ruta",
      //       type: "item",
      //       icon: "edit",
      //       url: "lrcg",
      //     },
      //   ],
      // },
      {
        id: "parametrization-routes",
        title: "Crear ruta",
        type: "item",
        icon: "plus-square",
        url: "routes/create-route",
      },
      {
        id: "parametrization-variables",
        title: "Crear variable",
        type: "item",
        icon: "plus",
        url: "variables/create-variable",
      },
    ],
  },

  //SECCIÓN DE DATA
  {
    id: "parametrization-section",
    type: "section",
    title: "Data",
    role: ["Gestor"],
    children: [
      {
        id: "report",
        title: "Reportes",
        type: "item",
        icon: "download",
        url: "general/report",
      },
      {
        id: "report",
        title: "Indicadores",
        type: "item",
        icon: "clipboard",
        url: "indicators/powerbi-indicators",
      },
    ],
  },
];
