import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-graph-opportunity-indicator',
  templateUrl: './graph-opportunity-indicator.component.html',
  styleUrls: ['./graph-opportunity-indicator.component.scss']
})
export class GraphOpportunityIndicatorComponent implements OnInit {

  @Input() dataIndicator:any = [];

  viewDetails = true;

  classIcon:string = 'avatar bg-light-success p-50 m-auto';
  avatar:number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  toggleButton(){
    this.viewDetails = !this.viewDetails
  }

}
