import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from "@angular/core";

import { CoreConfigService } from "@core/services/config.service";

@Component({
  selector: "app-graph-proportion-indicator",
  templateUrl: "./graph-proportion-indicator.component.html",
  styleUrls: ["./graph-proportion-indicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GraphProportionIndicatorComponent implements OnInit {
  // Data que se va a pintar del indicador
  @Input() dataIndicator: any = {};

  // Información del gráfico que se renderiza
  @ViewChild("goalChartRef") goalChartRef: any;
  public goalChartoptions;
  public isMenuToggled = true;

  constructor(private _coreConfigService: CoreConfigService) {}

  // Configuraciones que tendrá el gráfico con los valores que vienen en el input
  ngOnChanges() {
    this.goalChartoptions = {
      series: [this.dataIndicator.average_indicator],
      chart: {
        height: 245,
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      colors: [this.dataIndicator.color_indicator],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: "77%",
          },
          track: {
            background: "#ebe9f1",
            strokeWidth: "50%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#5e5873",
              fontSize: "2.86rem",
              fontWeight: "600",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          //gradientToColors: [colors.solid],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
    };
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe((config) => {
      // If Menu Collapsed Changes
      if (
        config.layout.menu.collapsed === true ||
        config.layout.menu.collapsed === false
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.goalChartoptions.chart.width =
            this.goalChartRef?.nativeElement.offsetWidth;
        }, 200);
      }
    });
  }
}
