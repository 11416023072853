// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  baseUrl: "https://api.10care.life",
  cognitoResetPasswordUrl:
    "https://jwt-10care-prod.auth.us-east-1.amazoncognito.com/forgotPassword?client_id=30s1g3cfctgo94fnimf0oodbuo&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fprod.10care.life%2F",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
