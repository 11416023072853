<div class="d-flex flex-column justify-content-between min-height-indicator">
  <!-- Contenedor del texto nombre del indicador -->
  <div class="col-12 pb-1">
    <p class="card-text text-center">{{ dataIndicator.name_indicator }}</p>
  </div>

  <!-- Contenedor del icono y días promedio del indicador -->
  <div class="col-12 d-flex flex-column align-items-center">
    <div [class]="classIcon">
      <div class="avatar-content" *ngIf="avatar == 0">
        <i data-feather="check" class="font-medium-5"></i>
      </div>
      <div class="avatar-content" *ngIf="avatar == 1">
        <i data-feather="alert-triangle" class="font-medium-5"></i>
      </div>
      <div class="avatar-content" *ngIf="avatar == 2">
        <i data-feather="x" class="font-medium-5"></i>
      </div>
    </div>

    <h1>{{ dataIndicator.average_days }}</h1>
    <p>días promedio</p>
  </div>

  <!-- Contenedor de promedio de días y botones de ver detalles y ocultar-->
  <div class="col-12 d-flex flex-column align-items-center">
    <p class="text-primary">(0.7) meses</p>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm col-6"
      rippleEffect
      *ngIf="viewDetails"
      (click)="toggleButton()"
    >
      Ver detalles
    </button>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm col-6"
      rippleEffect
      *ngIf="!viewDetails"
      (click)="toggleButton()"
    >
      Ocultar
    </button>

  </div>
</div>

<!-- Detalles del indicador -->
<div class="pt-2 col-12 col-sm-10 col-md-8 col-lg-10 m-auto" *ngIf="!viewDetails">
  <div class="py-1">
    <h6>246 pacientes entre 0 y 1 días</h6>
    <div class="progress-wrapper">
      <ngb-progressbar
        showValue="true"
        type="success"
        [height]="100"
        [value]="77"
      ></ngb-progressbar>
    </div>
  </div>
  <div class="py-1">
    <h6>53 pacientes entre 1 y 2 días</h6>
    <div class="progress-wrapper">
      <ngb-progressbar
        showValue="true"
        type="warning"
        [height]="100"
        [value]="17"
      ></ngb-progressbar>
    </div>
  </div>
  <div class="py-1">
    <h6>21 pacientes entre 3 o más días</h6>
    <div class="progress-wrapper">
      <ngb-progressbar
        showValue="true"
        type="danger"
        [height]="100"
        [value]="7"
      ></ngb-progressbar>
    </div>
  </div>
</div>
