import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter_opportunity_indicator'
})
export class FilterOpontunityIndicator implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   *
   * @returns {any}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => item.name_indicator.toLowerCase().includes(searchText.toLowerCase()));
  }
}
